import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const DoDontExample = makeShortcode("DoDontExample");
const Video = makeShortcode("Video");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Paths</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Composition</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "paths"
    }}>{`Paths`}</h2>
    <p>{`Elements in Carbon dance on the grid. Motion paths trace lines along the grid which never run diagonally.`}</p>
    <DoDontExample type="do" caption="When expanding or moving elements across the screen, stagger the timing of horizontal and vertical animations to create a path with a rounded corner." mdxType="DoDontExample">
      <Video vimeoId="310582887" mdxType="Video" />
    </DoDontExample>
    <br />
    <DoDontExample caption="Not staggering horizontal and vertical animations create a straight diagonal path. It breaks the grid and is harsh to the eye." mdxType="DoDontExample">
      <Video vimeoId="310582699" mdxType="Video" />
    </DoDontExample>
    <br />
    <Row mdxType="Row">
      <Column colLg={4} colMd={4} mdxType="Column">
        <DoDontExample type="do" aspectRatio="1:1" caption="When removing an item from the grid, thumbnails on the edge existing and re-entering container create a smooth transition." mdxType="DoDontExample">
          <Video vimeoId="310582738" mdxType="Video" />
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={4} mdxType="Column">
        <DoDontExample aspectRatio="1:1" caption="Thumbnails moving on diagonal paths feels sporadic and harsh." mdxType="DoDontExample">
          <Video vimeoId="310582775" mdxType="Video" />
        </DoDontExample>
      </Column>
    </Row>
    <br />
    <Row mdxType="Row">
      <Column colLg={4} colMd={4} mdxType="Column">
        <DoDontExample aspectRatio="1:1" type="do" caption="When sorting or shuffling items on the grid, always using rounded corner paths to visually organize the movements." mdxType="DoDontExample">
          <Video vimeoId="310582816" mdxType="Video" />
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={4} mdxType="Column">
        <DoDontExample aspectRatio="1:1" caption="Criss cross sorting appears disorganized. Avoid this motion path." mdxType="DoDontExample">
          <Video vimeoId="310582851" mdxType="Video" />
        </DoDontExample>
      </Column>
    </Row>
    <h2 {...{
      "id": "composition"
    }}>{`Composition`}</h2>
    <p>{`When multiple animated elements coexist or interact with each other within the same view, it is vital to make the many moving elements work together and form a coherent experience, to better provide way-finding and focus.`}</p>
    <h3 {...{
      "id": "consistency"
    }}>{`Consistency`}</h3>
    <h4 {...{
      "id": "semantic-consistency"
    }}>{`Semantic consistency`}</h4>
    <p>{`When elements convey the same meaning or perform the same function, use the same motion for them, and vice-versa. This helps to reinforce the meaning behind a movement and improves the user’s proficiency using the interface.`}</p>
    <p>{`In the example below, both expanding a row of a data table and opening a dropdown use a chevron and share a similar intent—to reveal content hidden in a seam. Therefore, they should use the same motion style (productive) and easing (entrance, standard). However, they should use slightly different durations due to their difference in size.`}</p>
    <DoDontExample type="do" caption="Comparing data table expansion and dropdown" mdxType="DoDontExample">
      <Video vimeoId="310581970" mdxType="Video" />
    </DoDontExample>
    <br />
    <h4 {...{
      "id": "spatial-consistency"
    }}>{`Spatial consistency`}</h4>
    <p>{`Pay attention to the spatial relationships between elements and screens, and information hierarchy. Visually similar elements may need the different motions to clarify their respective spatial locations.`}</p>
    <DoDontExample type="do" caption="When the new content panel is on a higher layer, motion is “sliding”, moving content within with the panel.  Also always dim the content below when new layer is introduced above." mdxType="DoDontExample">
      <Video vimeoId="310581999" mdxType="Video" />
    </DoDontExample>
    <br />
    <DoDontExample type="do" caption="When the new content panel is on the same layer, motion is “pushing”, revealing content within with a mask." mdxType="DoDontExample">
      <Video vimeoId="310582064" mdxType="Video" />
    </DoDontExample>
    <br />
    <h4 {...{
      "id": "intentional-inconsistency"
    }}>{`Intentional inconsistency`}</h4>
    <p>{`Conscientious use of inconsistency in motion highlights a difference in meaning or intent behind actions with similar visual appearance. Usually, forward motion in the direction of entrance signals affirmation, while reversing entrance motion signals cancellation.`}</p>
    <Row mdxType="Row">
      <Column colLg={4} colMd={4} mdxType="Column">
        <DoDontExample aspectRatio="1:1" type="do" caption="Use motion to reinforce meaning. Affirmative action here triggers a different exit motion for the modal than negation." mdxType="DoDontExample">
          <Video vimeoId="310582134" mdxType="Video" />
        </DoDontExample>
      </Column>
      <Column colLg={4} colMd={4} mdxType="Column">
        <DoDontExample aspectRatio="1:1" mdxType="DoDontExample">
          <Video vimeoId="310582167" mdxType="Video" />
        </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "continuity"
    }}>{`Continuity`}</h3>
    <p>{`Motion can help establish a sense of continuity between screens and experiences. Pay attention to shared elements across screens, such as title panels or buttons, to create a graceful transition.`}</p>
    <DoDontExample type="do" caption="Shared elements can effectively guide users through a multi-layered information architecture." mdxType="DoDontExample">
      <Video vimeoId="310582206" mdxType="Video" />
    </DoDontExample>
    <br />
    <DoDontExample caption="Continuous elements are for guidance and should not distract. Always finish a sequence with the important content on page." mdxType="DoDontExample">
      <Video vimeoId="310582279" mdxType="Video" />
    </DoDontExample>
    <h3 {...{
      "id": "sequence-and-stagger"
    }}>{`Sequence and stagger`}</h3>
    <p>{`When multiple elements need to animate, distribute their entrances over time instead of introducing everything at once. This will help the user understand the content and orientation.`}</p>
    <p>{`For example, staggering the entrance of table content by 20 ms significantly reduces the cognitive load. Depending on the number of staggered elements, the delay should be adjusted to ensure that total time is still within 500 ms.`}</p>
    <DoDontExample type="do" caption="Table with rows loading in at staggered timing." mdxType="DoDontExample">
      <Video vimeoId="310582972" mdxType="Video" />
    </DoDontExample>
    <br />
    <p>{`Sequence the loading of page content when possible. Start with the most stable content, such as static content and header, and end with the most important information, such as the primary button or a calculation result, to focus the user’s attention on them.`}</p>
    <DoDontExample type="do" caption="Sequencing of this interface prioritizes the primary button, and reserves data visualization for later when users begin to scroll, indicating intention to dive deeper." mdxType="DoDontExample">
      <Video vimeoId="310582919" mdxType="Video" />
    </DoDontExample>
    <br />
    <p>{`Follow this recommended sequence of different types of content when choreographing content entrance. Not all categories will be present in every experience.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Seq`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Static content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UI shell, top and side navigation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Static content (body)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Headers, written content, images`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dynamic content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content of a data table, query results from database`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary action button`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Animated content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Data visualizations`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      